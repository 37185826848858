import { PaginationOptions } from "react-data-table-component";
import { DocumentReasonType, DocumentTypeForCountryType } from "../types/Document";

export const eventTypes = {
  APPLICATION_LOADED: "applicationLoaded",
  LOCALE_CHANGE: "localeChange",
  TENANT_CHANGE: "tenantChange",
  AUTH_TOKEN_CHANGE: "authTokenChange",
  USER_DETAILS_CHANGE: "userDetailsChange",
  USER_PERMISSIONS_CHANGE: "userPermissionsChanged"
};

export const CHANNEL = "WEB";
export const LOYALTY_TEMPLATE_CODE_NAME: string = "sif_app02317_ecom_reg_tyc";
export const PRIVACY_TEMPLATE_CODE_NAME: string = "fsa_pdp";

export const BFF_BASE_URL = process.env.REACT_APP_CIAM_BFF;

export const COUNTRIES_DOCUMENT_TYPES: DocumentTypeForCountryType[] = [
  { country: "CL", documentTypes: ["RUT"] },
  { country: "PE", documentTypes: ["DNI", "CE"] },
  { country: "CO", documentTypes: ["CC", "CE"] },
  { country: "BR", documentTypes: ["CNPJ"] }
];

export const DOCUMENT_REASON: DocumentReasonType[] = [
  { text: "Fraude en devolución", value: "Fraude en devolución" },
  { text: "Fraude en cancelación", value: "Fraude en cancelación" },
  { text: "Fraude en falsa entrega", value: "Fraude en falsa entrega" },
  { text: "Fraude en reserva masiva de stock", value: "Fraude en reserva masiva de stock" },
  { text: "Fraude en errores de precio", value: "Fraude en errores de precio" },
  { text: "Fraude en suplantación de identidad", value: "Fraude en suplantación de identidad" }
];

export const jarvisUserKey = "subscription.jarvisusers";

export const whiteListPurpose = [
  "scalquin@falabella.cl",
  "onmiller@falabella.cl",
  "eecastrov@falabella.cl",
  "jaromeror@falabella.cl",
  "dalevegar@falabella.cl",
  "rterrevoli@falabella.cl",
  "natcanog@falabella.cl",
  "dgarridoesp@falabella.cl"
];

export const FIF = ["SP-PAYMENTS-CL", "SP-LOYALTY-CL", "SP-PAYMENTS-PE", "SP-LOYALTY-PE"];
export const FIFOptionList = [{ label: "RUT - DNI", value: 1 }];

export const OPTInOptionList = [
  { label: "BU", value: "PdP-BU" },
  { label: "Acotado", value: "Call-Center" },
  { label: "Ecosistema FIF", value: "TyC-FIF-eco" },
  { label: "Ecosistema Retail", value: "TyC-Retail-eco" },
  { label: "Ecosistema Falabella", value: "PdP-eco" },
  { label: "Terceros o Monetización", value: "OPTIN-TERCEROS" }
];

export const defaultTemplate = {
  PDP_CMR_PUNTOS: "PdP_CMRPuntos",
  TYC_CMR_PUNTOS: "TyC_CMRPuntos",
  LOYALTY_RULE: "loyalty_rule",
  POLICY_RULE: "policy_rule"
};

export const PERMISSION = {
  JARVIS_USER: "subscription.jarvisusers",
  BLOCKLIST_DOCUMENT: "up.ciam.blocklist",
  CONSENT_MANTAINER: "up.ciam.consent.maintainer",
  CUSTOMER_IDENTITY: "up.ciam.customer.identity",
  CUSTOMER_ACCOUNT: "up.ciam.customer.account",
  DELETE_ACCOUNT: "up.ciam.customer.account",
  DELETE_CUSTOMER: "up.ciam.customer.identity",
  LOCK_PASSWORD: "up.ciam.customer.account",
  UNLOCK_PASSWORD: "up.ciam.customer.account",
  AUDIT_LOG: "up.ciam.log.audit"
};

export const paginationRowsPerPageOptions: number[] = [20, 30, 40, 50];

export const paginationComponentOptions: PaginationOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Todos"
};
